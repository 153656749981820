import APIManager from "./APIManager";

const fetchCouponRecord = async (vendorId) => {
  const coupons = await APIManager.axios
    .get(`/v1/vendor/coupon?vendorId=${vendorId}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return coupons;
};

const fetchIndividualCoupon = async (id) => {
  const coupon = await APIManager.axios
    .get(`/v1/vendor/coupon/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return coupon;
};

const createCoupon = async (requiredObj) => {
  let formData = new FormData();
  formData.append("banner", requiredObj.banner);
  formData.append("vendorId", requiredObj.vendorId);
  formData.append("startDate", requiredObj.startDate);
  formData.append("expiryDate", requiredObj.expiryDate);
  formData.append("website", requiredObj.website);
  formData.append("code", requiredObj.code);
  formData.append("storeWide", requiredObj.storeWide);
  formData.append("discount", requiredObj.discount);
  formData.append("type", requiredObj.type);

  const response = await APIManager.axios
    .post("/v1/vendor/coupon", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
        return response.data;
    })
    .catch((err) => {
      if (typeof err.response.data.message !== "undefined") {
        return err.response.data.message;
      } else {
        return "Network error";
      }
    });
  return response;
};

const deleteCoupon = async (id) => {
  const response = await APIManager.axios
    .delete(`/v1/vendor/coupon/${id}`)
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      return err.response.data;
    });
  return response;
};

export default {
  fetchCouponRecord,
  fetchIndividualCoupon,
  createCoupon,
  deleteCoupon
};
