<template>
  <v-container>
    <!-- Delete Dialog -->
    <v-layout>
      <v-row>
        <v-dialog v-model="deleteDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Delete Confirmation</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <p>Are you sure you want to delete this task?</p>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="$emit('confirmDeletion', deleteId)">
                Yes
              </v-btn>
              <v-btn text @click="deleteDialog = false"> No </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-layout>

    <v-row>
      <v-col cols="12">
        <h2 id="text">{{ title }}</h2>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" md="4" sm="12" class="d-flex justify-md-end justify-sm-center">
        <v-text-field
          v-model="search"
          solo
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
          style="width: 80%"
          id="search"
        >
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4" sm="12" class="d-flex justify-md-end justify-sm-center">
        <v-btn id="button" tile @click="create" width="60%">
          <v-icon left>mdi-plus</v-icon>
          {{ buttonTitle }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="tableItems"
          item-key="id"
          class="elevation-1"
          :loading="loader"
        >
          <template v-slot:item.statusValue="{ item }">
            <v-chip
              id="chip"
              :class="item.statusValue"
              class="font-weight-bold"
              >{{ item.statusValue }}</v-chip
            >
          </template>
           <template v-slot:item.action="{ item }">
            <v-row>
              <v-col cols="12" md="6" v-if="showProductDetails" class="d-flex justify-end">
                <v-icon
                  elevation="0"
                  @click="$emit('viewProductDetails', item.id)"
                  >mdi-eye</v-icon
                >
              </v-col>
              <v-col cols="12" md="6" v-if="deleteButton" class="d-flex justify-start">
                <v-icon
                  elevation="0"
                  @click="showDeleteDialog(item.id)"
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    create: { type: Function },
    title: String,
    buttonTitle: String,
    headers: Array,
    tableItems: Array,
    chipStatus: String,
    loader:Boolean,
    viewDetails: Boolean,
    deleteButton: Boolean,
    deleteDialog: Boolean,
    showProductDetails: Boolean
  },
  data: () => ({
    search: "",
    valid: true,
    errorMessage: false,
    deleteId: null,
    productDialog: false
  }),
  methods: {
    cancel() {
      this.$refs.form.reset();
      this.props.dialog = false;
    },
    showDeleteDialog(id){
      this.deleteId = id;
      this.deleteDialog = true;
    }
  },
};
</script>