<template>
  <v-container class="my-10">
    <v-dialog v-model="deleteDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <p>Are you sure you want to delete this task?</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteProduct()"> Yes </v-btn>
          <v-btn text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog flat v-model="editDialog" width="75%">
      <product-edit-form :selectedProduct="selectedProduct" :cancel="cancel"></product-edit-form>
    </v-dialog>

    <v-row align="center">
      <v-col
        cols="12"
        md="4"
        sm="12"
        class="d-flex justify-md-start justify-sm-center"
      >
        <h2 id="text">Products</h2>
      </v-col>
      <v-spacer></v-spacer>
     
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <v-select dense outlined :items="genders" item-text="name" item-value="value" placeholder="Choose Gender"
          v-model="selectedProductFilter.gender" @change="genderTrigger"></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select dense outlined :items="categories" item-text="title" item-value="title" placeholder="Choose Category"
          v-model="selectedProductFilter.category" @change="categoryTrigger" ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="4"
        sm="12"
        class="d-flex justify-md-end justify-sm-center"
      >
        <v-btn id="button" tile @click="createPage" width="60%">
          <v-icon left>mdi-plus</v-icon>
          Create Product
        </v-btn>
      </v-col>
    </v-row>

    <!-- Product Card -->
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="3"
        v-for="product in products"
        :key="product.id"
      >
        <!-- <v-hover v-slot="{ hover }"> -->
        <v-card
          class="mx-auto pa-0 rounded-lg"
          max-width="332px"
          height="420px"
          outlined
          @click="viewProductDetails(product.id)"
        >
          <div>
            <v-img :src="`${product.photoUrl}`" style="height: 250px">
              <div class="align-self-right">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-btn text @click="showEditDialog(product.id)"
                          >Edit</v-btn
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-btn text @click="showDeleteDialog(product.id)"
                          >Delete</v-btn
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </v-img>
          </div>
          <v-card-title>{{ product.name }}</v-card-title>
          <v-card-text class="text-pre-wrap">
            <!-- <div>
              {{ product.description }}
            </div> -->
            <div v-if="product.discountedPrice !== 0">
              <span class="text-h6" style="color: #008499"
                >Rs. {{ product.discountedPrice }}</span
              >
              <div v-if="product.discount.amount !== 0">
                <span class="text-decoration-line-through">{{
                  product.price
                }}</span>
                <span v-if="product.discount.type == 'fixed'">
                  -Rs. {{ product.discount.amount }}</span
                >
                <span v-if="product.discount.type == 'percentage'">
                  -{{ product.discount.amount }}%</span
                >
              </div>
            </div>
            <!-- <div>
              <span class="text-h6" style="color: #008499"
                >Rs. {{ product.discountedPrice }}</span
              >
            </div>
            <div v-if="product.discount.amount !== 0">
              <span class="text-decoration-line-through">{{
                product.price
              }}</span>
              <span v-if="product.discount.type == 'fixed'"> -Rs. {{ product.discount.amount }}</span>
              <span v-if="product.discount.type == 'percentage'"> -{{ product.discount.amount }}%</span>
            </div> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductService from "../../services/ProductService";
import CategoryService from "../../services/CategoryService";

import ProductEditForm from "../../components/EditProductForm.vue";

export default {
  name: "Product",
  data: () => ({
    categories:[],
    genders:[{
      name:"Select none",
      value:null
    },{
      name:"Men",
      value:"men"
    },{
      name:"Women",
      value:"women"
    },
    {
      name:"Accessories",
      value:"all"
    }],
    selectedProductFilter: {
      category:null,
      keywords:null,
      gender: null,

    },
      products: [],
      individualProduct: {},
      selectedVendor: "",
      editDialog: false,
      deleteDialog: false,
      valid: false,
      isDisabled: false,
      selectedId: null,
      selectedProduct: {},
      errorMessage: false
    }),
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  components: { ProductEditForm },
  watch: {
    selectedVendor() {
      this.isDataLoaded = false;
      this.getProductRecord();
    },
  },
  created() {
    if (!this.selectedVendor) {
      this.selectedVendor = localStorage.getItem("selectedVendor");
    }
    this.getProductRecord();
  },

  methods: {
    genderTrigger(){
      this.getTagCategoryRecord();
      this.getProductRecord();
    },
    categoryTrigger(e){
      const selectedCategory = this.categories.find(category => category.title === e);
      this.selectedProductFilter.keywords= selectedCategory ?selectedCategory.keywords : null
      this.getProductRecord();
    },
    getTagCategoryRecord() {
      CategoryService.fetchTagCategories({category:this.selectedProductFilter.gender}).then(
        (response)=>{
          this.categories = [{ title: 'None', value: null }, ...response];
          
        })
    },
    getProductRecord() {
      ProductService.fetchProductRecord(this.selectedProductFilter).then((response) => {
        let requiredData = response;
        this.products = [];
        requiredData.map((item) => {
          if (item.vendorId == this.selectedVendor) {
            // Calculate discounted price
            let discountedPrice;
            if (item.discount.type == "fixed") {
              discountedPrice = item.price - item.discount.amount;
            } else if (item.discount.type == "percentage") {
              discountedPrice =
                item.price - item.discount.amount * 0.01 * item.price;
            }

            // Product Status
            if (item.status) {
              item = { statusValue: "Active", discountedPrice, ...item };
            } else {
              item = { statusValue: "Inactive", discountedPrice, ...item };
            }
            this.products.push(item);
          }
        });
        this.loader = false;
      });
    },
    createPage() {
      this.$router.push("/product-create");
    },
    viewProductDetails(id) {
      this.$router.push(`/product/${id}`);
    },
    showDeleteDialog(id) {
      this.selectedId = id;
      this.deleteDialog = true;
    },
    showEditDialog(id) {
      this.selectedId = id;
      this.selectedProduct = this.products.filter((product) => product.id == id)[0];
      this.editDialog = true;
    },
    async deleteProduct() {
      await ProductService.deleteProduct(this.selectedId).then(() => {
        this.getProductRecord();
      });
      this.deleteDialog = false;
    },
    cancel() {
      this.editDialog = false;
      this.deleteDialog = false;
    },
  },
};
</script>
