<template>
  <div>
    <v-navigation-drawer v-model="drawer" mini fixed app>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="@/assets/khoji-logo.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Khoji</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <v-list>
        <div v-for="item in items" :key="item.title">
          <v-divider v-if="item.title == 'Task'"></v-divider>
          <v-list-item v-if="!item.subItems" v-on:click="clicked(item.path)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-title v-text="item.title" />
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app style="background-color: #008499" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-row class="d-flex align-center">
        <v-col cols="1">
          <v-toolbar-title>Khoji</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="d-flex align-center mt-4">
          <v-responsive v-if="$vuetify.breakpoint.smAndDown">
            <v-icon>mdi-menu-down</v-icon>
          </v-responsive>
          <v-select
            v-else
            dense
            outlined
            :items="vendors"
            item-text="name"
            item-value="id"
            placeholder="Choose Vendor"
            v-model="selectedVendor"
            @change="saveVendor()"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4" class="d-flex justify-end">
          <v-menu
            transition="slide-y-transition"
            bottom
            :nudge-width="250"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-btn text icon class="mr-4" v-on="on">
                <v-icon large>mdi-account</v-icon>
              </v-btn>
            </template>
            <v-card class="cardBorderRadious">
              <v-list>
                <v-list-item-content class="pa-3">
                  <v-list-item-sub-title><b>Hello</b></v-list-item-sub-title>
                  <!-- <v-list-item-title> </v-list-item-title> -->
                </v-list-item-content>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  title="Logout"
                  color="grey darken-1"
                  @click="logout"
                >
                  <v-icon class="mr-1">mdi-lock-open</v-icon>
                  Log out
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    drawer: false,
    vendors: [],
    selectedVendor: "",
    items: [
      {
        title: "Overview",
        icon: "mdi-home",
        href: "/overview",
        path: "/overview",
      },
      // {
      //   title: "Analytics",
      //   icon: "mdi-chart-line",
      //   href: "/analytics",
      //   path: "/analytics",
      // },
      {
        title: "Products",
        icon: "mdi-tshirt-crew",
        href: "/products",
        path: "/products",
      },
      {
        title: "Shop",
        icon: "mdi-shopping",
        href: "/shop",
        path: "/shop",
      },
      // {
      //   title: "Product Tag",
      //   icon: "mdi-tag",
      //   href: "/product-tag",
      //   path: "/product-tag",
      // },
      {
        title: "Sales",
        icon: "mdi-ticket-percent-outline",
        href: "/sales",
        path: "/sales",
      },
    ],
    mini: true,
  }),
  created() {
    this.getVendors();
  },
  mounted() {},
  methods: {
    clicked(path) {
      this.$router.push({ path: path });
      this.drawer = false;
    },
    logout() {
      localStorage.clear();
      this.$router.push("/");
      window.location.reload();
    },
    getVendors() {
      const storedVendors = JSON.parse(localStorage.getItem("vendors"));
      storedVendors.forEach((item) => {
        this.vendors.push({ id: item.id, name: item.name });
      });
      localStorage.setItem("selectedVendor", this.vendors[0].id);
      this.selectedVendor = JSON.parse(localStorage.getItem("selectedVendor"));
      
    },
    saveVendor() {
      localStorage.setItem("selectedVendor", this.selectedVendor);
      window.dispatchEvent(
        new CustomEvent("selectedVendor-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("selectedVendor"),
          },
        })
      );
    },
  },
};
</script>
<style>
.toolbar-color {
  background-color: #008499 !important;
}

.icon-color .v-btn__content {
  color: #ffffff;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>