<template>
  <v-card>
    <v-card-title>Product Tag</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <div>
            <img
              class="mt-4"
              :src="image"
              style="max-width: 100%; max-height: 50vh;"
              crossorigin="anonymous"
            />
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <div v-if="features.length !== 0">
            <v-row>
              <v-col cols="12" md="4">
                <h2>TopCategory</h2>
                <br />
                <p>{{ topCategory }}</p>
              </v-col>
              <v-col cols="12" md="4">
                <h2>Category</h2>
                <br />
                <p>{{ category }}</p>
              </v-col>
            </v-row>
          </div>
          <div v-if="features.length !== 0">
            <h2>Tags</h2>
          </div>
          <br />
          <div>
            <v-row>
              <v-col
                cols="4"
                v-for="(feature, index) in features"
                :key="index"
                class="mb-0 pb-0"
              >
                <p>{{ feature.key }} - {{ feature.value }}</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4"></v-col>
        <v-col cols="12" md="4" class="d-flex align-left">
          <h3 class="pr-2" id="text">Are the tags correct?</h3>
          <v-btn class="mr-2" id="button" small @click="$emit('selectTags')"
            >Yes</v-btn
          >

          <v-btn id="button" small @click="$emit('editTags')">No</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ProductService from "../services/ProductService";
export default {
  props: {
    image: Array,
    features: Array,
    topCategory: String,
    category: String,
    productId: Number,
  },
  methods: {
    async createInquiry() {
      let productId = this.productId;
      await ProductService.createInquiry(productId).then((res) => {
        if (res.message == "success") {
          this.$router.push("/products");
        }
      });
    },
  },
};
</script>