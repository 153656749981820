<template>
  <v-container class="my-10">
    <CouponList
      v-if="isDataLoaded"
      :title="title"
      :create="showCreatePage"
      :buttonTitle="buttonTitle"
      :headers="headers"
      :tableItems="coupon"
      :deleteButton="true"
      v-on:confirmDeletion="deleteCoupon"
      :deleteDialog="deleteDialog"
    ></CouponList>
  </v-container>
</template>

<script>
import CouponService from "../../services/CouponService";
import CouponList from "../../components/DataListPage.vue";
import moment from "moment";
export default {
  name: "Coupon",
  data: () => ({
    headers: [
      { text: "Id", align: "left", sortable: true, value: "id" },
      { text: "Start Date", align: "left", sortable: true, value: "formattedStartDate" },
      {
        text: "Expiry Date",
        align: "left",
        sortable: true,
        value: "formattedExpiryDate",
      },
      { text: "Website", align: "left", sortable: true, value: "website" },
      { text: "Discount", align: "left", sortable: true, value: "discount" },
      { text: "Type", align: "left", sortable: true, value: "type" },
      { text: "Actions", align: "center", sortable: true, value: "action" },
    ],
    title: "Coupon Overview",
    buttonTitle: "Create Coupon",
    coupon: [],
    addCouponDialog: false,
    errorMessage: false,
    loader: true,
    deleteDialog: false,
    isDisabled: false,
    selectedVendor: "",
    isDataLoaded: false,
  }),
  components: { CouponList },
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  watch: {
    selectedVendor() {
      this.isDataLoaded = false;
      // this.coupon = [];
      this.getCouponRecord();
    },
  },
  created() {
    if (!this.selectedVendor) {
      this.selectedVendor = localStorage.getItem("selectedVendor");
    }
    this.getCouponRecord();
  },
  beforeCreate() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/");
    }
  },
  methods: {
    async getCouponRecord() {
      await CouponService.fetchCouponRecord(this.selectedVendor).then(
        (response) => {
          let requiredData = response;
          let formattedStartDate = moment(requiredData.startDate).format('YYYY-MM-DD');
          let formattedExpiryDate = moment(requiredData.expiryDate).format('YYYY-MM-DD');
          this.coupon = [];
          requiredData.map((item) => {

              if (item.active) {
                item = { statusValue: "Active", formattedStartDate, formattedExpiryDate, ...item };
              } else {
                item = { statusValue: "Inactive", formattedStartDate, formattedExpiryDate, ...item };
              }
              this.coupon.push(item);
            
          });
          this.loader = false;
          this.isDataLoaded = true;
        }
      );
    },
    cancel() {
      this.$refs.form.reset();
      this.addCouponDialog = false;
      this.errorMessage = false;
    },
    async createCoupon() {
      let requiredObj = { ...this.newCoupon, vendorId: this.selectedVendor };
      const nameFound = this.coupon.some(
        (obj) => obj.name === requiredObj.name
      );
      if (nameFound) {
        this.errorMessage = true;
        this.$refs.form.reset();
      } else {
        await CouponService.createCoupon(requiredObj).then(() => {
          window.location.reload();
        });
      }
    },
    showCreatePage() {
      this.$router.push("/coupon/create");
    },
    validation() {
      this.isDisabled = false;
      if (this.$refs.form.validate()) {
        (this.isDisabled = true), this.createCoupon();
      } else {
        this.$refs.form.reset();
      }
    },
    async deleteCoupon(id) {
      await CouponService.deleteCoupon(id).then(() => {
        this.getCouponRecord();
      });
      this.deleteDialog = false;
    },
    viewCouponDetails(id) {
      this.$router.push(`/coupon/id=${id}`);
    },
  },
};
</script>
