import APIManager from "./APIManager";

const fetchOverview = async (vendorId) => {
    const products = await APIManager.axios
      .get(`/v1/vendor/${vendorId}/overview`)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  
    return products;
  };

  export default {
    fetchOverview,
  };