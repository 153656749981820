<template>
  <v-container class="my-10">
    <!-- Product Details -->
    <v-layout>
      <v-row>
        <v-dialog
          v-model="productDialog"
          persistent
          max-width="600px"
          fullscreen
        >
          <v-card>
            <v-card-title>
              <span class="headline">Products</span>
              <v-col class="text-right">
                <v-btn icon @click="cancel()">
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    lg="3"
                    v-for="product in saleProducts"
                    :key="product.id"
                  >
                    <!-- <v-hover v-slot="{ hover }"> -->
                    <v-card
                      class="mx-auto pa-0 rounded-lg"
                      max-width="332px"
                      max-height="350px"
                      outlined
                      @click="viewProductDetails(product.id)"
                    >
                      <div>
                        <v-img
                          :src="`${product.photos[0].photoUrl}`"
                          style="height: 250px"
                        >
                        </v-img>
                      </div>
                      <v-card-title>{{ product.name }}</v-card-title>
                      <v-card-text>{{ product.description }}</v-card-text>
                    </v-card>
                    <!-- </v-hover> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-layout>

    <SaleList
      v-if="isDataLoaded"
      :title="title"
      :create="viewCreatePage"
      :buttonTitle="buttonTitle"
      :headers="headers"
      :tableItems="sales"
      v-on:viewProductDetails="viewProductDetails"
      v-on:confirmDeletion="deleteSale"
      :deleteDialog="deleteDialog"
      :showProductDetails="true"
      :deleteButton="true"
    ></SaleList>
  </v-container>
</template>

<script>
import SaleService from "../../services/SaleService";
import SaleList from "../../components/DataListPage.vue";
import moment from "moment";
export default {
  name: "Sale",
  data: () => ({
    headers: [
      { text: "Id", align: "left", sortable: true, value: "id" },
      {
        text: "Discount",
        align: "left",
        sortable: true,
        value: "discountValue",
      },
      { text: "Start Date", align: "left", sortable: true, value: "startDate" },
      {
        text: "Expiry Date",
        align: "left",
        sortable: true,
        value: "expiryDate",
      },
      { text: "Status", align: "center", sortable: true, value: "statusValue" },
      { text: "Action", align: "center", sortable: true, value: "action" },
    ],
    title: "Sales Overview",
    buttonTitle: "Create Sale",
    sales: [],
    saleProducts: [],
    newSale: {
      name: "",
      address: "",
      latitude: "",
      longitude: "",
    },
    addSaleDialog: false,
    loader: true,
    isDisabled: false,
    selectedVendor: "",
    isDataLoaded: false,
    productDialog: false,
    deleteDialog: false
  }),
  components: { SaleList },
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  watch: {
    selectedVendor() {
      this.isDataLoaded = false;
      this.getSaleRecord();
    },
  },
  created() {
    if (!this.selectedVendor) {
      this.selectedVendor = localStorage.getItem("selectedVendor");
    }
    this.getSaleRecord();
  },
  beforeCreate() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/");
    }
  },
  methods: {
    async getSaleRecord() {
      await SaleService.fetchSaleRecord(this.selectedVendor).then(
        (response) => {
          let requiredData = response;
          this.sales = [];
          requiredData.map((item) => {
            let discountValue =
              item.type == "fixed"
                ? `Rs. ${item.discount}`
                : `${item.discount}%`;
            item.startDate = moment(item.startDate).format("YYYY-MM-DD");
            item.expiryDate = moment(item.expiryDate).format("YYYY-MM-DD");
            let statusValue = item.expiryDate >= moment().format("YYYY-MM-DD") ? "Active" : "Inactive";
            item = { statusValue, discountValue, ...item };
            this.sales.push(item);
          });
          this.loader = false;
          this.isDataLoaded = true;
        }
      );
    },
    viewCreatePage() {
      this.$router.push(`/sales/create`);
    },
    validation() {
      this.isDisabled = false;
      if (this.$refs.form.validate()) {
        (this.isDisabled = true), this.createSale();
      } else {
        this.$refs.form.reset();
      }
    },
    viewProductDetails(saleId) {
      this.$router.push(`/sale/${saleId}`);
    },
    async deleteSale(saleId) {
      await SaleService.deleteSale(saleId, this.selectedVendor).then(() => {
        this.getSaleRecord();
      });
      this.deleteDialog = false;
    },
    cancel() {
      this.productDialog = false;
    },
  },
};
</script>
