import APIManager from "./APIManager";

const createProductTag = async (image) => {
  const response = await APIManager.axios.post("/v1/vendor/product-tag", {
      image
    }, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      if (res.data.message == "success") {
        return res.data.data;
      }
    })
    .catch((err) => {
      if (typeof err.response !== "undefined") {
        return err.response.message;
      } else {
        return "Network error";
      }
    });
  return response;
};

const fetchProductTag = async () => {
  const productTags = await APIManager.axios
    .get(`/v1/vendor/product-tag`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return productTags;
};

const editProductTag = async (reqObj) => {
  const productTags = await APIManager.axios
    .patch(`/v1/vendor/product-tag`, reqObj)
    .then(
      response => {
        return response.data;

      },
    )
    .catch((err) => {
      return err.response.data
    })
  return productTags;
}

const editTagVisibility = async (selectedTags, productId) => {
  const productTags = await APIManager.axios
    .patch(`/v1/vendor/product-tag/update/visibility?productId=${productId}`, {selectedTags})
    .then(
      response => {
        return response.data;

      },
    )
    .catch((err) => {
      return err.response.data
    })
  return productTags;
}

export default {
  createProductTag,
  fetchProductTag,
  editTagVisibility,
  editProductTag
};