<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  data: () => ({
    series: [
      {
        data: [
          {
            y: "50",
            x: "Accessories",
          },
          {
            y: "30",
            x: "Clothing",
          },
          {
            y: "20",
            x: "Footwear",
          },
        ],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 380,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: "category",
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
        },
      }
    },
  }),
  components: { apexchart: VueApexCharts },
};
</script>