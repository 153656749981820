<template>
  <v-container class="my-10 no-gutters">
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-row no-gutters>
      <v-breadcrumbs :items="breadcrumbItems" class="ml-0 pl-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6" align="left" justify="center">
        <h2 id="text">{{ this.productDetails.name }}</h2>
        <p class="grey--text--lighten text-pre-wrap">
          {{ this.productDetails.description }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12" md="6" justify="center">
        <!-- <div> -->
        <v-carousel
          style="max-width: 80%; height: 60vh"
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(item, i) in images" :key="i">
            <v-img
              :src="`${item}`"
              height="100%"
              style="max-width: 100%"
              contain
            ></v-img>
          </v-carousel-item>
        </v-carousel>
        <!-- </div> -->
        <br />
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="6">
            <div v-if="productDetails.ProductTags.length !== 0">
              <h2 id="text">TopCategory</h2>
              <p>{{ topCategory }}</p>
              <br />
              <h2 id="text">Category</h2>
              <p v-if="category">{{ category }}</p>
              <p v-else>-</p>
              <br />
              <h2 id="text" v-if="subCategory">SubCategory</h2>
              <p>{{ subCategory }}</p>
              <br />
              <h2  v-if="this.productDetails.sizes" id="text">Sizes</h2>
              <p>{{ this.productDetails.sizes }}</p>
            </div>
          </v-col>
          <v-col cols="6">
            <div v-if="productDetails.ProductTags.length !== 0">
              <h2 id="text">Tags</h2>
            </div>
            <div v-for="feature in features" :key="feature.id">
              <v-row>
                <v-col cols="12">
                  <p v-if="feature.type == 'feature'">
                    {{ feature.name }} - {{ feature.ProductTag.name }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductService from "../../services/ProductService";
export default {
  name: "ProductDetails",
  data() {
    return {
      productId: this.$route.params.id,
      topCategory: "",
      category: "",
      subCategory: "",
      productDetails: {},
      images: [],
      shops: [],
      loading: true,
      features: [],
      breadcrumbItems: [
        {
          text: "Products",
          disabled: false,
          href: "/products",
        },
        {
          text: "Product Details",
          disabled: true,
        },
      ],
    };
  },
  created() {
    this.getProductDetails();
  },
  methods: {
    async getProductDetails() {
      const id = this.$route.params.id;
      await ProductService.fetchIndividualProduct(id).then((response) => {
        this.productDetails = response;
        this.productDetails.photos.forEach((photo) => {
          this.images.push(photo.photoUrl);
        });
        this.productDetails.ProductTags.forEach((item) => {
          if (item.type === "category") {
            if (item.name === "Top Category")
              this.topCategory = item.ProductTag.name;
            else if (item.name === "Category")
              this.category = item.ProductTag.name;
          }
          if (item.type === "subcategory") {
            this.subCategory = item.ProductTag.name;
          }
        });

        this.features = this.productDetails.ProductTags;
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
body {
  background-color: #eeeeee;
  padding: 150px;
}
</style>