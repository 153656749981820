import APIManager from "./APIManager";

const fetchShopRecord = async () => {
  const shops = await APIManager.axios
    .get(`/v1/vendor/shop`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return shops;
};

const fetchIndividualShop = async (id) => {
  const shop = await APIManager.axios
    .get(`/v1/vendor/shop/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return shop;
};

const createShop = async (requiredObj) =>{
  const response = await APIManager.axios.post("/v1/vendor/shop", requiredObj).then((res)=>{
    return res.data.message
  }).catch((err)=>{
    return err.response.data;
  })

  return response;
}

const deleteShop = async (id) =>{
  const response  = await APIManager.axios.delete(`/v1/vendor/shop/${id}`).then((res)=>{
    return res.data.message
  }).catch((err)=>{
    return err.response.data;
  });
  return response;
}

export default { fetchShopRecord , fetchIndividualShop, createShop, deleteShop};
