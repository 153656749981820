/* eslint-disable no-unused-vars */
const objectWithoutNulls = (objectWithNulls) => {
  if( objectWithNulls === undefined){
    return {};
  }
  return Object.fromEntries(
    Object.entries(objectWithNulls).filter(([key, value]) => value !== null)
  );
};
module.exports = {
  objectWithoutNulls,
};
