<template>
  <div id="chart">
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  data: () => ({
    series: [
      {
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: "Inter, sans-serif",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "Viewers per day",
        align: "left",
        style:{
          fontWeight: 'bold',
          fontSize:  '20px',
          fontFamily: "Inter, sans-serif",
        }
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "01/04/2023",
          "02/04/2023",
          "03/04/2023",
          "04/04/2023",
          "05/04/2023",
          "06/04/2023",
        ],
      },
    },
  }),
  components: { apexchart: VueApexCharts },
};
</script>