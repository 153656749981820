<template>
  <v-container class="my-10">
    <v-row>
      <v-col cols="12">
        <h2 id="text">Overview</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="d-flex justify-center"
            >No. of Shops</v-card-title
          >
          <br />
          <v-card-text class="d-flex justify-center" style="font-size: 40px">
            <p>{{ reports.shopCount }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="d-flex justify-center"
            >No. of Products</v-card-title
          >
          <br />
          <v-card-text class="d-flex justify-center" style="font-size: 40px">
            <p>{{ reports.productCount }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="d-flex justify-center"
            >Active Sales</v-card-title
          >
          <br />
          <v-card-text class="d-flex justify-center" style="font-size: 40px">
            <p>{{ reports.saleCount }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 id="text">Inquiries</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="inquiry" class="elevation-1">
          <template v-slot:item.status="{ item }">
            <v-chip
              v-if="item.solved"
              style="background: #d8f7e6; color: #00692a"
              class="font-weight-bold"
              >{{ item.status }}</v-chip
            >
            <v-chip
              v-else
              style="background: #fcdfe6; color: #e3473c"
              class="font-weight-bold"
              >{{ item.status }}</v-chip
            >
          </template>
          <template v-slot:item.action="{ item }">
            <v-row>
              <v-col cols="6" class="d-flex justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      @click="viewProductDialog(item.Product.id)"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Query</span>
                </v-tooltip>
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      @click="solveInquiryDialog(item.id)"
                      :disabled="item.solved"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Mark as done</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Product Dialog -->
    <v-layout>
      <v-row>
        <v-dialog v-model="productDialog" max-width="600px">
          <v-card>
            <div>
              <v-col class="text-right">
                <v-btn icon @click="cancel()">
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">{{
                  selectedProduct.name
                }}</v-card-title>

                <v-card-subtitle>{{
                  selectedProduct.description
                }}</v-card-subtitle>

                <v-card-text>
                  <v-alert
                    text
                    type="info"
                    title="Query"
                    border="left"
                  >
                    {{selectedProduct.query}}
                  </v-alert>
                </v-card-text>
              </div>

              <v-avatar class="ma-3" size="200" tile>
                <v-img :src="`${selectedProduct.image}`"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-dialog>
      </v-row>
    </v-layout>

    <!-- Solve inquiry confirmation dialog -->
    <v-layout>
      <v-row>
        <v-dialog v-model="inquiryDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Solve Inquiry</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <p>Is this inquiry solved?</p>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="solveInquiry()"> Yes </v-btn>
              <v-btn text @click="inquiryDialog = false"> No </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import OverviewService from "../services/OverviewService";
import InquiryService from "../services/InquiryService";
import moment from "moment";
export default {
  data: () => ({
    headers: [
      { text: "Product", align: "left", sortable: true, value: "productName" },
      { text: "Date", align: "left", sortable: true, value: "date" },
      { text: "User", align: "left", sortable: true, value: "userName" },
      { text: "Phone", align: "left", sortable: true, value: "User.phone" },
      { text: "Email", align: "left", sortable: true, value: "User.email" },
      { text: "Status", align: "left", sortable: true, value: "status" },
      { text: "Action", align: "center", sortable: true, value: "action" },
    ],
    reports: [],
    inquiry: [],
    selectedProduct: {},
    selectedVendor: null,
    isDataLoaded: false,
    productDialog: false,
    inquiryDialog: false,
    inquiryId: null,
  }),
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  created() {
    if (!this.selectedVendor) {
      this.selectedVendor = localStorage.getItem("selectedVendor");
      this.getRecords();
      this.getInquiry();
    }
  },
  watch: {
    selectedVendor() {
      this.isDataLoaded = false;
      this.getRecords();
      this.getInquiry();
    },
  },
  methods: {
    async getRecords() {
      await OverviewService.fetchOverview(this.selectedVendor).then(
        (response) => {
          this.reports = response;
        }
      );
    },
    async getInquiry() {
      await InquiryService.fetchInquiry(this.selectedVendor).then(
        (response) => {
          let requiredObj = response.map((item) => {
            let userName = item.User.firstName + " " + item.User.lastName;
            let productName = item.Product.name;
            let date = moment(item.createdAt).format("YYYY-MM-DD");
            let status = item.solved ? "Solved" : "Pending";
            return { userName, productName, date, status, ...item };
          });
          this.inquiry = requiredObj;
        }
      );
    },
    solveInquiryDialog(id) {
      this.inquiryDialog = true;
      this.inquiryId = id;
    },
    async solveInquiry() {
      await InquiryService.editInquiry(
        this.inquiryId,
        this.selectedVendor
      ).then(() => {
        this.getInquiry();
        this.inquiryDialog = false;
      });
    },
    viewProductDialog(productId) {
      let inquiry = this.inquiry.filter((item) => item.Product.id == productId);
      this.selectedProduct = inquiry[0].Product;
      this.selectedProduct.image = this.selectedProduct.photos[0].photoUrl;
      this.selectedProduct.query = inquiry[0].description;
      this.productDialog = true;
    },
    cancel() {
      this.productDialog = false;
    },
  },
};
</script>