<template>
  <v-container class="my-10">
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog v-model="formLoader" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <div>
      <v-form ref="form" v-model="valid">
        <v-row v-if="selectedTag.category">
          <v-col cols="12" md="4">
            <v-select
              :items="categories"
              label="Category"
              v-model="selectedTag.category"
            ></v-select>
          </v-col>
          <v-col md="1" class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  @click="getProductTags()"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Reset Tags</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="selectedTag.features">
          <v-col cols="4" v-for="item in features" :key="item.key">
            <v-select
              :items="item.value"
              :label="item.key"
              v-model="selectedTag.features[item.key]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-btn @click="validation()" id="button" :disabled="isDisabled"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import ProductService from "../services/ProductService";
import ProductTagService from "../services/ProductTagService";
export default {
  props: { productId: Number, formLoader: Boolean },
  data: () => ({
    productTags: {},
    selectedTag: {
      topCategory: null,
      category: null,
    },
    selectedFeatures: {},
    newTag: {},
    selectedVendor: "",
    previewImage: [],
    previewPrimaryImage: {},
    valid: false,
    showImage: false,
    loading: false,
    isError: false,
    isDisabled: false,
    createFailed: false,
    isCreated: false,
    reportDialog: false,
    url: null,
    features: [],
    subCategories: [],
    topCategory: [],
    categories: [],
    loader: false,
    errorMessage: "",
  }),
  watch: {
    "selectedTag.topCategory"(item) {
      this.getCategories(item);
    },
    "selectedTag.category"(category) {
      this.getFeatures(this.selectedTag.topCategory, category);
    },
  },
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  created() {
    if (!this.selectedVendor) {
      this.selectedVendor = JSON.parse(localStorage.getItem("selectedVendor"));
    }
    this.getProductTags();
  },
  methods: {
    async getProductTags() {
      this.topCategory = [];
      await ProductTagService.fetchProductTag().then((response) => {
        this.productTags = response;
        for (var topCategory in this.productTags) {
          this.topCategory.push(topCategory);
        }
      });
      this.getProductDetails();
    },
    getCategories(topCategory) {
      this.features = [];
      this.categories = [];
      this.subCategories = [];
      for (var category in this.productTags[topCategory]) {
        this.categories.push(category);
      }
    },
    getFeatures(topCategory, category) {
      this.features = [];
      for (var feature in this.productTags[topCategory][category]) {
        this.features.push({
          key: feature,
          value: this.productTags[topCategory][category][feature],
        });
      }
    },
    getSelectedFeatures(tagName, feature) {
      this.selectedTag.features[tagName] = feature;
    },
    async getProductDetails() {
      await ProductService.fetchIndividualProduct(this.productId).then(
        (response) => {
          let tags = {
            category: "",
            topCategory: "",
            features: {},
          };
          response.ProductTags.map((tag) => {
            if (tag.type == "feature" || tag.type == "subcategory") {
              tags.features[tag.name] = tag.ProductTag.name;
            } else if (tag.type == "category" && tag.name == "Category") {
              tags.category = tag.ProductTag.name;
            } 
            else if (tag.type == "category" && tag.name == "Top Category") {
              tags.topCategory = tag.ProductTag.name;
            }
          });
          this.selectedTag = tags;
        }
      );
      this.formLoader = false;
    },
    validation() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        (this.isDisabled = true), this.updateProductTag();
      } else {
        this.valid = false;
      }
    },
    async updateProductTag() {
      this.loading = true;
      let tags = {};
      tags.Category = this.selectedTag.category;
      tags.topCategory = this.selectedTag.topCategory;

      for (var feature in this.selectedTag.features) {
        tags[feature] = this.selectedTag.features[feature];
      }

      let requiredObj = {
        productId: this.productId,
        tags,
      };

      await ProductTagService.editProductTag(requiredObj).then(() => {
        this.loading = false;
        this.$emit('status-change', 'selectTag');
      });
    },
  },
};
</script>