<template>
  <v-container class="my-10">
    <v-dialog flat v-model="addShopDialog" width="60%">
      <v-card>
        <v-layout>
          <v-flex wrap>
            <v-card>
              <v-card-title>
                <span class="headline">Add Shop</span>
                <v-col class="text-right">
                  <v-btn icon @click="cancel()">
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-card-title>
              <v-divider></v-divider>
              <v-form ref="form">
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-col cols="12" v-if="errorMessage">
                        <v-alert
                          color="error"
                          transition="scale-transition"
                          icon="mdi-alert"
                          outlined
                          class="text-xs-center"
                          style="border-radius: 5px"
                        >
                          <b>Shop Exists</b>
                        </v-alert>
                      </v-col>
                      <v-col cols="12" md="4">
                        <!-- <v-select
                        items="1"
                          v-model="newShop.vendorId"
                          label="Vendor Id"
                          required
                          outlined
                          :rules="idRules"
                        ></v-select> -->
                      </v-col>
                      <v-col md="8"></v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newShop.name"
                          label="Name"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newShop.address"
                          label="Address"
                          required
                          :rules="addressRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newShop.latitude"
                          label="Lalitude"
                          required
                          :rules="latitudeRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="newShop.longitude"
                          label="Longitude"
                          required
                          :rules="longitudeRules"
                        ></v-text-field>
                      </v-col>
                    </v-layout>
                  </v-container>
                  <v-divider></v-divider>
                  <br />
                  <v-col class="text-right">
                    <v-btn dense id="button" @click="validation()" :disabled="isDisabled">Save</v-btn>
                    <v-btn dense id="button" class="ml-4" @click="cancel()"
                      >Cancel</v-btn
                    >
                  </v-col>
                </v-card-text>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <ShopList
      v-if="isDataLoaded"
      :title="title"
      :create="showDialog"
      :buttonTitle="buttonTitle"
      :headers="headers"
      :tableItems="Shops"
      :deleteButton="true"
      v-on:confirmDeletion="deleteShop"
      :deleteDialog="deleteDialog"
    ></ShopList>
  </v-container>
</template>

<script>
import ShopService from "../../services/ShopService";
import ShopList from "../../components/DataListPage.vue";
export default {
  name: "Shop",
  data: () => ({
    headers: [
      { text: "Id", align: "left", sortable: true, value: "id" },
      { text: "Shop Name", align: "left", sortable: true, value: "name" },
      { text: "Address", align: "left", sortable: true, value: "address" },
      { text: "Actions", align: "center", sortable: true, value: "action" },
    ],
    title: "Shop Overview",
    buttonTitle: "Create Shop",
    Shops: [],
    newShop: {
      name: "",
      address: "",
      latitude: "",
      longitude: "",
    },
    addShopDialog: false,
    idRules: [(v) => !!v || "Id is required"],
    nameRules: [(v) => !!v || "Name is required"],
    addressRules: [(v) => !!v || "Address is required"],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    latitudeRules: [(v) => !!v || "Latitude is required"],
    longitudeRules: [(v) => !!v || "Longitude is required"],
    errorMessage: false,
    loader: true,
    deleteDialog: false,
    isDisabled: false,
    // selectedVendor: JSON.parse(localStorage.getItem("selectedVendor")),
    selectedVendor: "",
    isDataLoaded: false,
  }),
  components: { ShopList },
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  watch: {
    selectedVendor() {
      this.isDataLoaded = false;
      // this.Shops = [];
      this.getShopRecord();
    },
  },
  created() {
    if(!this.selectedVendor){
      this.selectedVendor = localStorage.getItem('selectedVendor');
    }
    this.getShopRecord();
  },
  beforeCreate() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/");
    }
  },
  methods: {
    async getShopRecord() {
      await ShopService.fetchShopRecord().then((response) => {
        let requiredData = response;
        this.Shops = [];
        requiredData.map((item) => {
          if (item.vendorId == this.selectedVendor) {
            if (item.status) {
              item = { statusValue: "Active", ...item };
            } else {
              item = { statusValue: "Inactive", ...item };
            }
            this.Shops.push(item);
          }
        });

        this.loader = false;
        this.isDataLoaded = true;
      });
    },
    cancel() {
      this.$refs.form.reset();
      this.addShopDialog = false;
      this.errorMessage = false;
    },
    async createShop() {
      let requiredObj = { ...this.newShop, vendorId: this.selectedVendor };
      const nameFound = this.Shops.some((obj) => obj.name === requiredObj.name);
      if (nameFound) {
        this.errorMessage = true;
        this.$refs.form.reset();
      } else {
        await ShopService.createShop(requiredObj).then(() => {
          window.location.reload();
        });
      }
    },
    showDialog() {
      this.addShopDialog = true;
    },
    validation() {
      this.isDisabled = false;
      if (this.$refs.form.validate()) {
        this.isDisabled = true,
        this.createShop();
      } else {
        this.$refs.form.reset();
      }
    },
    async deleteShop(id) {
      await ShopService.deleteShop(id).then(() => {
        this.getShopRecord();
      });
      this.deleteDialog = false;
    },
    viewShopDetails(id) {
      this.$router.push(`/shop/id=${id}`);
    },
  },
};
</script>
