import Vue from 'vue'
import VueRouter from 'vue-router'
import Shop from '../views/Shop/ShopList.vue'
import Login from '../views/Login.vue'
import ShopDetails from "../views/Shop/ShopDetails.vue"
import Product from "../views/Product/ProductList.vue"
import ProductDetails from "../views/Product/ProductDetails.vue"
import CreateProduct from "../views/Product/CreateProduct.vue"
import ProductTag from '../views/Product/ProductTag.vue'
// import UpdateProductTag from '../views/Product/UpdateProductTag.vue'
import Overview from '../views/Overview.vue'
import Analytics from '../views/Analytics.vue'
import Coupon from "../views/Coupon/CouponList.vue";
import CreateCoupon from "../views/Coupon/CreateCoupon.vue";
import Sales from "../views/Sale/SaleList.vue";
import CreateSale from "../views/Sale/CreateSale.vue";
import SaleDetails from "../views/Sale/SaleDetails.vue";
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/shop/:id',
    name: 'ShopDetails',
    component: ShopDetails
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: Coupon
  },
  {
    path: '/coupon/create',
    name: 'CreateCoupon',
    component: CreateCoupon
  },
  {
    path: '/products',
    name: 'Product',
    component: Product
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    component: ProductDetails
  },
  {
    path: '/product-create',
    name: 'CreateProduct',
    component: CreateProduct
  },
  {
    path: '/product-tag',
    name: 'ProductTag',
    component: ProductTag
  },
  // {
  //   path: '/product-tag/update/productId=:pId',
  //   name: 'UpdateProductTag',
  //   component: UpdateProductTag
  // },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales
  },
  {
    path: '/sales/create',
    name: 'CreateSale',
    component: CreateSale
  },
  {
    path: '/sale/:id',
    name: 'SaleDetails',
    component: SaleDetails
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router