var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{attrs:{"id":"text"}},[_vm._v("Overview")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("No. of Shops")]),_c('br'),_c('v-card-text',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"40px"}},[_c('p',[_vm._v(_vm._s(_vm.reports.shopCount))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("No. of Products")]),_c('br'),_c('v-card-text',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"40px"}},[_c('p',[_vm._v(_vm._s(_vm.reports.productCount))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Active Sales")]),_c('br'),_c('v-card-text',{staticClass:"d-flex justify-center",staticStyle:{"font-size":"40px"}},[_c('p',[_vm._v(_vm._s(_vm.reports.saleCount))])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{attrs:{"id":"text"}},[_vm._v("Inquiries")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.inquiry},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.solved)?_c('v-chip',{staticClass:"font-weight-bold",staticStyle:{"background":"#d8f7e6","color":"#00692a"}},[_vm._v(_vm._s(item.status))]):_c('v-chip',{staticClass:"font-weight-bold",staticStyle:{"background":"#fcdfe6","color":"#e3473c"}},[_vm._v(_vm._s(item.status))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.viewProductDialog(item.Product.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Query")])])],1),_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","disabled":item.solved,"icon":""},on:{"click":function($event){return _vm.solveInquiryDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark as done")])])],1)],1)]}}])})],1)],1),_c('v-layout',[_c('v-row',[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.productDialog),callback:function ($$v) {_vm.productDialog=$$v},expression:"productDialog"}},[_c('v-card',[_c('div',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.selectedProduct.name))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.selectedProduct.description))]),_c('v-card-text',[_c('v-alert',{attrs:{"text":"","type":"info","title":"Query","border":"left"}},[_vm._v(" "+_vm._s(_vm.selectedProduct.query)+" ")])],1)],1),_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"200","tile":""}},[_c('v-img',{attrs:{"src":("" + (_vm.selectedProduct.image))}})],1)],1)])],1)],1)],1),_c('v-layout',[_c('v-row',[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.inquiryDialog),callback:function ($$v) {_vm.inquiryDialog=$$v},expression:"inquiryDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Solve Inquiry")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('p',[_vm._v("Is this inquiry solved?")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.solveInquiry()}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.inquiryDialog = false}}},[_vm._v(" No ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }