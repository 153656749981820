<template>
  <v-container fill-height class="py-8">
    <v-row>
      <v-col cols="12">
        <h2>{{ shopDetails.name }}</h2>
        </v-col
      >
      <!-- <v-col cols="12"><v-divider></v-divider></v-col> -->
      <v-col cols="12">
        <v-card class="px-4">
          <v-card-title>
            <v-icon>mdi-map-marker</v-icon>
            Location
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-8">
            <v-row class="d-flex justify-space-between">
              <v-chip style="background: #b2dfdb; color: #00796b" >
                {{
                  shopDetails.address.charAt(0).toUpperCase() +
                  shopDetails.address.slice(1)
                }}
              </v-chip>

              <!-- <v-btn text id="button">
                <v-icon>mdi-pencil</v-icon>
                Edit
              </v-btn> -->
            </v-row>
          </v-card-text>
          <!-- <v-card-actions>
            <v-img src="@/assets/map.png"></v-img>
          </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopService from "../../services/ShopService";
export default {
  name: "App",
  data: () => ({
    shopDetails: [],
  }),
  created() {
    this.getShopDetails();
  },
  methods: {
    getShopDetails() {
      const id = this.$route.params.id;
      ShopService.fetchIndividualShop(id).then((response) => {
        this.shopDetails = response;
      });
    },
  },
};
</script>
