import APIManager from "./APIManager";

const handleLogin = async (queryObj) => {
  const message = await APIManager.axios
    .post(`/v1/vendor/login`, queryObj)
    .then((res) => {
      if (res.data.message == "success") {
        localStorage.setItem("token", res.data.token);
        const vendor = res.data.user.UserVendors;
        localStorage.setItem("vendors", JSON.stringify(vendor));
        // const storedVendor = localStorage.getItem("vendors")
        return "success";
      }
    })
    .catch((err) => {
      if (typeof err.response !== "undefined") {
        return err.response.message;
      } else {
        return "Network error";
      }
    });
  return message;
};

export default {
  handleLogin,
};
