import APIManager from "./APIManager";

const fetchSaleRecord = async (vendorId) => {
  const sales = await APIManager.axios
    .get(`/v1/vendor/${vendorId}/sale`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return sales;
};

const fetchIndividualSale = async (id, vendorId) => {
  const sale = await APIManager.axios
    .get(`/v1/vendor/${vendorId}/sale/${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return err.response.data;
    });

  return sale;
};

const createSale = async (requiredObj, vendorId) => {
  let formData = new FormData();
  formData.append("title", requiredObj.title);
  formData.append("startDate", requiredObj.startDate);
  formData.append("expiryDate", requiredObj.expiryDate);
  formData.append("type", requiredObj.type);
  formData.append("discount", requiredObj.discount);
  formData.append("banner", requiredObj.banner);
  requiredObj.products.map((e) => {
    formData.append("products", e);
  });
  const response = await APIManager.axios
    .post(`v1/vendor/${vendorId}/sale`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (typeof err.response.data.message !== "undefined") {
        return err.response.data.message;
      } else {
        return "Network error";
      }
    });

  return response;
}

const deleteSale = async (id, vendorId) => {
  const response = await APIManager.axios.delete(`/v1/vendor/${vendorId}/sale/${id}`).then((res) => {
    return res.data.message
  }).catch((err) => {
    return err.response.data;
  });
  return response;
}

const editDiscount = async (vendorId, salesId, productId, reqObj) => {
  const productDetails = await APIManager.axios
    .patch(`/v1/vendor/${vendorId}/sale/${salesId}/product/${productId}/discount`, reqObj)
    .then(
      response => {
        return response.data;

      },
    )
    .catch((err) => {
      return err.response.data
    })
  return productDetails;
}
export default {
  fetchSaleRecord,
  fetchIndividualSale,
  createSale,
  deleteSale,
  editDiscount
};