<template>
  <v-container class="my-10">
    <v-row>
      <h2 id="text">Product Tag</h2>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- <v-form>
          <input ref="fileInput" type="file" @input="pickFile" accept="image/png, image/jpg, image/jpeg"/>
          <v-btn v-if="previewImage" id="button" @click="postImage()">Add</v-btn>
        </v-form> -->
        <v-file-input
          @change="selectImage"
          accept="image/*"
          label="File input"
        ></v-file-input>
        <v-btn v-if="previewImage" id="button" @click="postImage()"
          >Upload</v-btn
        >
      </v-col>
      <v-col cols="12" md="4">
        <img
          v-if="previewImage"
          class="mt-4"
          :src="previewImage"
          style="max-width: 50%"
          crossorigin="anonymous"
        />
      </v-col>
      <v-col cols="12" md="4">
        <div>
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loader"
          ></v-progress-circular>
        </div>
        <div v-if="features.length !== 0">
          <h2>TopCategory</h2>
          <p>{{ topCategory }}</p>
          <br />
          <h2>Category</h2>
          <p>{{ category }}</p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div v-if="features.length !== 0">
          <h2>Tags</h2>
        </div>
        <div v-for="feature in features" :key="feature.key">
          <v-row>
            <v-col cols="6">
              <p v-if="feature.key !== 'Category'">
                {{ feature.key }} - {{ feature.value }}
              </p>
            </v-col>
            <!-- <v-col cols="6">
              <span v-if="feature.key !== 'Category'">{{feature.probability}}</span>
            </v-col> -->
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductTagService from "../../services/ProductTagService";
export default {
  data: () => ({
    previewImage: {},
    currentImage: null,
    url: null,
    features: [],
    topCategory: "",
    category: "",
    loader: false,
  }),
  methods: {
    // pickFile() {
    //   let input = this.$refs.fileInput;
    //   let file = input.files;
    //   if (file && file[0]) {
    //     let reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.previewImage = e.target.result;
    //     };
    //     reader.readAsDataURL(file[0]);

    //     this.$emit("input", file[0]);
    //   }
    // },
    async postImage() {
      // this.$refs.fileInput.validate();
      this.loader = true;
      let image = this.currentImage;
      await ProductTagService.createProductTag(image).then((response) => {
        this.features = response;
        this.features.forEach((item) => {
          if (item.key === "Category") {
            let categories = item.value.split("/");
            this.topCategory = categories[0];
            this.category = categories[1];
          }
        });
        this.loader = false;
      });
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    selectImage(image) {
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
      this.message = "";
    },
  },
};
</script>