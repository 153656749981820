<template>
  <v-container class="my-10">
    <v-row>
      <v-col cols="12">
        <h2 id="text">Analytics</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="d-flex justify-center"
            >Total Viewers</v-card-title
          >
          <br />
          <v-card-text class="d-flex justify-center" style="font-size: 40px">
            <p>1056</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="d-flex justify-center"
            >Unique Products Viewed</v-card-title
          >
          <br />
          <v-card-text class="d-flex justify-center" style="font-size: 40px">
            <p>240</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="d-flex justify-center"
            >Average Views per Product</v-card-title
          >
          <br />
          <v-card-text class="d-flex justify-center" style="font-size: 40px">
            <p>5%</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Viewers Line Chart -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <LineChart></LineChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="5" cols="12" class="pr-md-4 pr-0">
        <v-card style="height: 70vh">
          <!-- <Loading v-if="loading5" /> -->
          <v-card-title>Performance</v-card-title>
          <v-card-text>
            <!-- <div v-if="dataArrive"> -->
            <v-row class="px-4 mt-8">
              <v-col class="mx-0 px-0" md="6" cols="12">
                <h3 class="text-center grey--text">Top 10</h3>
                <v-data-table
                  :headers="headers"
                  :items="growthRecords"
                  :items-per-page="5"
                  class="elevation-0"
                  hide-default-footer
                  hide-default-header
                >
                  <template v-slot:item="row">
                    <tr>
                      <td>{{ row.item.shopName }}</td>
                      <td>
                        <span
                          v-if="row.item.visitingGrowthRate > 0"
                          style="color:  #4CD964;font-weight:bold; !important;"
                          class="ml-n6"
                        >
                          <v-icon
                            style="
                              color: #4cd964 !important;
                              font-weight: bold !important;
                            "
                            small
                            >arrow_upward</v-icon
                          >{{ row.item.visitingGrowthRate }}%
                        </span>
                        <span
                          v-if="row.item.visitingGrowthRate < 0"
                          style="color: red; font-weight: bold"
                          class="ml-n6"
                        >
                          <v-icon
                            style="font-weight: bold; color: #ff3b30 !important"
                            small
                            >arrow_downward</v-icon
                          >{{ row.item.visitingGrowthRate }}%
                        </span>
                        <span
                          v-if="row.item.visitingGrowthRate == 0"
                          style="color: grey; font-weight: bold"
                        >
                          <v-icon
                            style="font-weight: bold; color: grey !important"
                            size="10"
                            class="mr-1"
                            >fa-minus</v-icon
                          >{{ row.item.visitingGrowthRate }}%
                        </span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider vertical></v-divider>
              </v-col>
              <v-col class="mx-0 px-0" md="6" cols="12">
                <h3 class="text-center grey--text">Bottom 10</h3>
                <v-data-table
                  :headers="headers"
                  :items="deGrowthRecords"
                  :items-per-page="5"
                  class="elevation-0"
                  hide-default-footer
                  hide-default-header
                >
                  <template v-slot:item="row">
                    <tr>
                      <td>{{ row.item.shopName }}</td>
                      <td>
                        <span
                          v-if="row.item.visitingGrowthRate > 0"
                          style="color: #4cd964; font-weight: bold"
                          class="ml-n6"
                        >
                          <v-icon
                            style="
                              font-weight: bold !important;
                              color: #4cd964 !important;
                            "
                            small
                            >arrow_upward</v-icon
                          >{{ row.item.visitingGrowthRate }}%
                        </span>
                        <span
                          v-if="row.item.visitingGrowthRate < 0"
                          style="color: red; font-weight: bold"
                        >
                          <v-icon
                            style="font-weight: bold; color: #ff3b30 !important"
                            small
                            class="ml-n6"
                            >arrow_downward</v-icon
                          >{{ row.item.visitingGrowthRate * -1 }}%
                        </span>
                        <span
                          v-if="row.item.visitingGrowthRate == 0"
                          style="color: grey; font-weight: bold"
                        >
                          <v-icon
                            style="font-weight: bold; color: grey !important"
                            size="10"
                            class="mr-1"
                            >fa-minus</v-icon
                          >{{ row.item.visitingGrowthRate * -1 }}%
                        </span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <!-- </div> -->
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="7">
        <v-card style="height: 70vh">
          <v-card-title>Viewers per categories</v-card-title>
          <v-card-text>
            <BarChart></BarChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LineChart from "../components/ViewersLineChart";
import BarChart from "../components/ViewersBarChart.vue";
export default {
  data: () => ({
    headers: [
      { text: "Product", width: 100, sortable: false, value: "productName" },
      {
        text: "Viewers",
        value: "viewers",
        width: 100,
      },
    ],
  }),
  components: { LineChart, BarChart },
};
</script>