<template>
  <v-container class="my-10 no-gutters">
    <v-row no-gutters>
      <v-breadcrumbs :items="breadcrumbItems" class="ml-0 pl-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>

        <v-card-title>
          <span class="headline">Add/Update Product Dsicount</span>
          <v-col class="text-right">
            <v-btn icon @click="cancel()">
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-col cols="12">
                  <v-text-field v-model="selectedProduct.discount" label="Discount"></v-text-field>
                </v-col>
              </v-layout>
            </v-container>
            <v-divider></v-divider>
            <br />
            <v-col class="text-right">
              <v-btn dense id="button" @click="validation()">Save</v-btn>
              <v-btn dense id="button" class="ml-4" @click="cancel()">Cancel</v-btn>
            </v-col>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" sm="6">
                    <strong>Discounts Upto</strong>
                  </v-col>
                  <v-col cols="12" sm="6">
                    {{ saleDetails.discountType === "fixed" ? `Rs.${saleDetails.discount}` : `${saleDetails.discount}%` }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <strong>Start Date</strong>
                  </v-col>
                  <v-col cols="12" sm="6">
                    {{ saleDetails.startDate }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <strong>Expiry Date</strong>
                  </v-col>
                  <v-col cols="12" sm="6">
                    {{ saleDetails.expiryDate }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Products -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-card-title>
              <strong id="text">Products</strong>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="3" v-for="product in saleDetails.SaleProducts" :key="product.id">
                    <!-- <v-hover v-slot="{ hover }"> -->
                    <v-card class="mx-auto pa-0 rounded-lg" max-width="332px" max-height="400px" outlined
                      @click="viewProductDetails(product.id)">
                      <div>
                        <v-img :src="`${product.photos[0].photoUrl}`" style="height: 250px">
                          <div class="align-self-right">
                            <v-menu>
                              <template v-slot:activator="{ on, attrs }" v-if="status === 'Active'">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-card>
                                <v-list>
                                  <v-list-item>
                                    <v-list-item-title @click="showEditDialog(product.id)">Add/Update
                                      Discount</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-menu>
                          </div>
                        </v-img>
                      </div>
                      <v-card-title>{{ product.name }}</v-card-title>
                      <v-card-text class="text-pre-wrap">
                        <div>
                          {{ product.description }}
                        </div>
                        <div>
                          <span class="text-h6" style="color: #008499">Rs. {{ product.discountedPrice }}</span>
                        </div>
                        <span v-if="product.discount > 0">
                          <span class="text-decoration-line-through">{{
                            product.price
                          }}</span>
                          <span v-if="saleDetails.type == 'fixed'">
                            -Rs. {{ product.discount }}</span>
                          <span v-if="saleDetails.type == 'percentage'">
                            -{{ product.discount }}%</span>
                        </span>
                        <span v-else><br/></span>
                      </v-card-text>
                    </v-card>
                    <!-- </v-hover> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SaleService from "../../services/SaleService";
import moment from "moment";
export default {
  name: "SaleDetails",
  data() {
    return {
      topCategory: "",
      category: "",
      saleDetails: {},
      images: [],
      shops: [],
      status: "",
      valid: false,
      productId: "",
      selectedProduct: {},
      editDialog: false,
      breadcrumbItems: [
        {
          text: 'Sales',
          disabled: false,
          href: '/sales',
        },
        {
          text: 'Sale Details',
          disabled: true
        }
      ],
    };
  },
  created() {
    this.getSaleDetails();
  },
  methods: {
    getSaleDetails() {
      const id = this.$route.params.id;
      const vendorId = localStorage.getItem("selectedVendor");
      let saleDetails = {};
      SaleService.fetchIndividualSale(id, vendorId).then((response) => {
        saleDetails = response;
        saleDetails.startDate = moment(response.startDate).format("YYYY-MM-DD");
        saleDetails.expiryDate = moment(response.expiryDate).format(
          "YYYY-MM-DD"
        );
        saleDetails.SaleProducts.forEach((item) => {
          console.log(item)
          if (saleDetails.type == "fixed") {
            item.discountedPrice = item.price - item.discount;
          } else {
            item.discountedPrice =
              item.price - item.discount * 0.01 * item.price;
          }
        });
        this.saleDetails = saleDetails;
        this.status = saleDetails.expiryDate >= moment().format("YYYY-MM-DD") ? "Active" : "Inactive";
      });
    },

    viewProductDetails(id) {
      this.$router.push(`/product/${id}`);
    },
    showEditDialog(id) {
      this.productId = id;
      this.selectedProduct = this.saleDetails.SaleProducts.filter((product) => product.id == id)[0];
      this.editDialog = true;
    },
    cancel() {
      this.editDialog = false;
    },
    validation() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        this.editDiscount();
      } else {
        this.valid = false;
      }
    },
    editDiscount() {
      const vendorId = localStorage.getItem("selectedVendor");
      SaleService.editDiscount(vendorId, this.$route.params.id, this.selectedProduct.id, this.selectedProduct).then(
        () => {
          window.location.reload();
        }
      );
      this.cancel();
    },
  },
};
</script>
<style scoped>
body {
  background-color: #eeeeee;
  padding: 150px;
}
</style>