<template>
  <v-container class="my-10">
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-row class="d-flex align-center justify-center" v-if="isError">
      <v-col cols="12" md="4">
        <v-dialog v-model="isError" width="20%">
          <v-card>
            <v-card-title class="d-flex align-center justify-center">
              <div>
                <v-icon large color="#c7ae78">mdi-alert-circle-outline</v-icon>
              </div>
            </v-card-title>
            <v-card-text class="d-flex align-center justify-center">
              <div>
                {{ errorMessage }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-action
              class="d-flex align-center justify-center"
              style="background-color: #c7ae78"
            >
              <span @click="isError = false"><v-btn text>Dismiss</v-btn></span>
            </v-card-action>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>Create Sale</v-card-title>
      <v-card-text style="width: 100%">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="4">
              <v-file-input
                v-model="newSale.banner"
                accept="image/*"
                label="Banner"
                required
                :rules="bannerRules"
              ></v-file-input>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Title"
                v-model="newSale.title"
                :rules="titleRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                :items="discountType"
                v-model="newSale.type"
                label="Discount Type"
                required
                :rules="typeRules"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Discount"
                v-model="newSale.discount"
                :rules="discountRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newSale.startDate"
                    label="Start Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :rules="dateRules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="newSale.startDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startDateMenu.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-menu
                ref="expiryDateMenu"
                v-model="expiryDateMenu"
                :close-on-content-click="false"
                :return-value.sync="expiryDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newSale.expiryDate"
                    label="Expiry Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :rules="dateRules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="newSale.expiryDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="expiryDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.expiryDateMenu.save(expiryDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="newSale.products"
                :items="products"
                chips
                color="blue-grey lighten-2"
                label="Products"
                item-text="name"
                item-value="id"
                multiple
                required
                :rules="productRules"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="`${data.item.photoUrl}`"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <v-img :src="`${data.item.photoUrl}`"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        data.item.description
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-btn @click="validation()" id="button" :disabled="isDisabled"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SaleService from "../../services/SaleService";
import ProductService from "../../services/ProductService";
export default {
  data: () => ({
    newSale: {
      banner: null,
      title: "",
      startDate: "",
      expiryDate: "",
      discount: null,
      type: "",
      products: [],
    },
    startDate: null,
    expiryDate: null,
    discountType: ["fixed", "percentage"],
    selectedVendor: "",
    products: [],
    valid: false,
    loading: false,
    isError: false,
    isDisabled: false,
    createFailed: false,
    startDateMenu: false,
    expiryDateMenu: false,
    loader: false,
    errorMessage: "",
    typeRules: [(v) => !!v || "Discount Type is required"],
    discountRules: [
      (v) => !!v || "Discount is required",
      (v) => /^\d+$/.test(v) || "Amount should contain only numbers",
    ],
    dateRules: [(v) => !!v || "Date is required"],
    productRules: [(v) => !!v || "Product is required"],
    titleRules: [(v) => !!v || "Title is required"],
    bannerRules: [(v) => !!v || "Banner is required"],
  }),
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  created() {
    if (!this.selectedVendor) {
      this.selectedVendor = JSON.parse(localStorage.getItem("selectedVendor"));
      this.getProductRecord();
    }
  },
  methods: {
    getProductRecord() {
      ProductService.fetchProductRecord().then((response) => {
        let requiredData = response;
        this.products = [];
        requiredData.map((item) => {
          if (item.vendorId == this.selectedVendor) {
            if (item.price > 0) {
              this.products.push(item);
            }
          }
        });
        this.loader = false;
      });
    },
    async createSale() {
      this.loading = true;
      let vendorId = this.selectedVendor;
      let requiredObj = this.newSale;
      await SaleService.createSale(requiredObj, vendorId).then((response) => {
        if (response.message == "success") {
          this.loading = false;
          this.$router.push("/sales");
        } else if (response == "Duplicate Sale name") {
          this.loading = false;
          this.isError = true;
          this.errorMessage = response;
          this.isDisabled = false;
        } else {
          this.createFailed = true;
          this.errorMessage = "Network Error";
          this.isDisabled = false;
        }
      });
    },
    validation() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        (this.isDisabled = true), this.createSale();
      } else {
        this.valid = false;
      }
    },
    remove(item) {
      const index = this.newSale.products.indexOf(item.id);
      if (index >= 0) this.newSale.products.splice(index, 1);
    },
  },
};
</script>