<template>
  <v-container>
    <v-card>
      <v-card-title> Create Coupon </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="4">
              <v-file-input
                v-model="newCoupon.banner"
                accept="image/*"
                label="Banner"
                required
                :rules="imageRules"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                required
                :rules="websiteRules"
                v-model="newCoupon.website"
                label="Website"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                required
                :rules="codeRules"
                v-model="newCoupon.code"
                label="Code"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-col cols="12" md="4">
            <v-menu
              :close-on-content-click="contentClick"
              :nudge-right="0"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Date"
                  readonly
                  :value="fromDateDisp"
                  v-on="on"
                  v-model="dateRangeText"
                  background-color="white"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="selectedPeriod" range></v-date-picker>
            </v-menu>
          </v-col>

          <v-row>
            <v-col cols="12" md="4">
              <v-radio-group v-model="newCoupon.type" row>
                <template v-slot:label>
                  <div>Discount Type</div>
                </template>
                <v-radio label="Fixed amount" value="fixed"> </v-radio>
                <v-radio label="Percent" value="percentage"> </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                required
                :rules="discountRules"
                v-model="newCoupon.discount"
                label="Discount"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-radio-group v-model="newCoupon.storeWide" row>
                <template v-slot:label>
                  <div>Store Wide</div>
                </template>
                <v-radio label="Yes" value="true"> </v-radio>
                <v-radio label="No" value="false"> </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-btn id="button" @click="validation">Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CouponService from "../../services/CouponService";
import moment from "moment";
export default {
  name: "CouponCreate",
  data: () => ({
    contentClick: false,
    fromDateMenu: false,
    toDateMenu: false,
    fromDateVal: null,
    fromDate: moment().format("YYYY-MM-DD"),
    selectedPeriod: [],
    newCoupon: {
      banner: null,
      vendorId: null,
      startDate: null,
      expiryDate: null,
      website: "",
      code: "",
      storeWide: false,
      discount: null,
      type: "",
    },
    selectedVendor: null,
    radios: false,
    imageRules: [(v) => !!v || "Banner image is required"],
    dateRules: [(v) => !!v || "Date is required"],
    websiteRules: [(v) => !!v || "Website is required"],
    codeRules: [(v) => !!v || "Code is required"],
    discountRules: [(v) => !!v || "Discount is required"],
  }),
  watch: {
    selectedPeriod: function () {
      if (this.selectedPeriod.length == 1) {
        this.contentClick = false;
      }
      if (this.selectedPeriod.length == 2) {
        this.arrangeDates();
      }
    },
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
    },
    dateRangeText() {
      return this.selectedPeriod.join(" ~ ");
    },
    endDate() {
      return this.endDateFormat(this.selectedPeriod);
    },
  },
  mounted() {
    window.addEventListener("selectedVendor-localstorage-changed", (event) => {
      this.selectedVendor = event.detail.storage;
    });
  },
  created() {
    if (!this.selectedVendor) {
      this.selectedVendor = JSON.parse(localStorage.getItem("selectedVendor"));
    }
  },
  methods: {
    arrangeDates() {
      this.contentClick = true;
      var date1 = new Date(this.selectedPeriod[0]);
      var date2 = new Date(this.selectedPeriod[1]);

      if (date1 > date2) {
        var b = this.selectedPeriod[0];
        this.selectedPeriod[0] = this.selectedPeriod[1];
        this.selectedPeriod[1] = b;
      }
      this.newCoupon.startDate = this.selectedPeriod[0];
      this.newCoupon.expiryDate = this.selectedPeriod[1];
    },
    endDateFormat: function (val) {
      return this.$moment().subtract(val, "days");
    },
    validation() {
      if (this.$refs.form.validate()) {
        this.createCoupon();
      } else {
        this.$refs.form.reset();
      }
    },
    async createCoupon() {
      this.newCoupon.vendorId = this.selectedVendor;
      let requiredObj = this.newCoupon;
      await CouponService.createCoupon(requiredObj).then((response) => {
        console.log(response)
        window.location.reload();
      });
    },
  },
};
</script>