<template>
  <v-card>
    <v-card-title>Select Product Tag</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <div>
            <img
              class="mt-4"
              :src="image"
              style="max-width: 100%; height: 50vh"
              crossorigin="anonymous"
            />
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <div v-if="formattedFeatures.length !== 0">
            <v-row>
              <v-col
                cols="4"
                v-for="(feature, index) in formattedFeatures"
                :key="index"
                class="mb-0 pb-0"
              >
                <v-checkbox
                  :label="`${feature.key} - ${feature.value}`"
                  @change="getSelectedTags(feature.key)"
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-btn @click="updateProductTag()" id="button" :disabled="isDisabled"
            >Submit</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ProductTagService from '../services/ProductTagService';
export default {
  props: {
    image: Array,
    features: Array,
    topCategory: String,
    category: String,
    productId: Number,
  },

  data: () => ({
    selectedTags: [],
    isDisabled: false,
    formattedFeatures: []
  }),

  created(){
    this.formattedFeatures = this.features.filter((feature) => feature.key !== 'Subcategory')
  },

  methods: {
    getSelectedTags(tag){
        this.selectedTags.push(tag);
    },

    async updateProductTag() {
      await ProductTagService.editTagVisibility(this.selectedTags, this.productId).then((res) => {
        if (res.message == "success") {
          this.$router.push("/products");
        }
      });
    },
  },
};
</script>